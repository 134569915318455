import { Cubit } from "blac-next";
import { TranslationKey } from "src/types/translationKey";
import { UserLifelineItemControllerService } from "@9amhealth/openapi/generated/services/UserLifelineItemControllerService";
import { LoincCodingCode } from "src/constants/fhir";
import { CustomObservation } from "src/lib/fhir";
import dayjs, { Dayjs } from "dayjs";
import reportErrorSentry from "src/lib/reportErrorSentry";

type DailyReadingsState = {
  loading: boolean;
  logsThisWeek: number;
  goalCompletion: number;
  title: TranslationKey;
  description: TranslationKey;
  datesWithReadings: Dayjs[];
  datesWithMissed: Dayjs[];
};

const titleBase = "dailyReadings.progressTitle";
const descriptionBase = "dailyReadings.progressDescription";

export default class DailyReadingsBloc extends Cubit<DailyReadingsState> {
  weightReadings: CustomObservation[] = [];
  // first day of the week, 0 = sunday, 1 = monday
  startOfWeekDay: 1 | 0 = 1;
  today = dayjs();
  dataLoaded = false;

  constructor() {
    super({
      loading: true,
      logsThisWeek: 0,
      goalCompletion: 0,
      datesWithReadings: [],
      datesWithMissed: [],
      title: `${titleBase}_addToday`,
      description: `${descriptionBase}_generic`
    });

    try {
      const urlQuery = new URLSearchParams(window.location.search);
      const todayDay = urlQuery.get("day");
      const num = Number(todayDay);
      if (todayDay && !isNaN(num)) {
        this.today = dayjs().day(0).add(num, "day");
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  get startOfWeek(): Dayjs {
    const todayWeekDay = this.today.day();

    if (this.startOfWeekDay - 1 === todayWeekDay) {
      // offset which is the same as the day of the week
      return this.today.clone().subtract(1, "day").day(this.startOfWeekDay);
    }

    return this.today.clone().day(this.startOfWeekDay);
  }

  get endOfWeek(): Dayjs {
    return this.startOfWeek.clone().add(6, "day");
  }

  get completedWeek(): boolean {
    const { logsThisWeek } = this.state;
    return logsThisWeek === 7;
  }

  get todayHasReading(): boolean {
    const { datesWithReadings } = this.state;
    return Boolean(datesWithReadings.find((d) => d.isSame(this.today, "day")));
  }

  get todayIsLastDayOfWeek(): boolean {
    return this.today.isSame(this.endOfWeek, "day");
  }

  // user has missed most of the week
  get fallingBehind(): boolean {
    const { datesWithMissed } = this.state;
    return datesWithMissed.length >= 4;
  }

  loadData = async () => {
    try {
      this.patch({ loading: true });
      const { data } = await UserLifelineItemControllerService.getLifelineItems(
        ["hl7_fhir_r4_lab_value"]
      );
      const bodyWeightReadings = data
        .filter(
          (r) =>
            r.deserializedPayload.observation.code.coding[0].code ===
            LoincCodingCode.weight
        )
        .map(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          (userLifelineItem): CustomObservation => ({
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            ...userLifelineItem.deserializedPayload.observation,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
            sourceType:
              userLifelineItem.deserializedPayload?.source?.type ?? "",
            id: userLifelineItem.id
          })
        );

      this.weightReadings = [];
      this.parseReadingsForState(bodyWeightReadings);
      this.setDynamicState();
      this.dataLoaded = true;
    } catch (e) {
      reportErrorSentry(e);
    }
    this.patch({ loading: false });
  };

  setDynamicState = () => {
    let title: TranslationKey = `${titleBase}_addToday`;
    let description: TranslationKey = `${descriptionBase}_generic`;

    if (this.completedWeek) {
      title = `${titleBase}_perfectWeek`;
      description = `${descriptionBase}_perfectWeek`;
    } else if (this.todayHasReading) {
      title = `${titleBase}_loggedToday`;
    } else if (this.fallingBehind && this.todayIsLastDayOfWeek) {
      title = `${titleBase}_fallingBehind`;
      description = `${descriptionBase}_fallingBehind`;
    }

    this.patch({ title, description });
  };

  parseReadingsForState = (bodyWeightReadings: CustomObservation[]) => {
    const daysWithMissed: Dayjs[] = [];
    const readingsThisWeek: CustomObservation[] = [];
    const datesWithReadings: Dayjs[] = [];

    for (const date of this.listOfDatesDisplay) {
      const weightReading = bodyWeightReadings.find((r) =>
        dayjs(r.effectiveDateTime).isSame(date, "day")
      );
      if (weightReading) {
        this.weightReadings.push(weightReading);
        datesWithReadings.push(date);
      }

      if (this.listOfDates.find((d) => d.isSame(date, "day"))) {
        if (weightReading) {
          readingsThisWeek.push(weightReading);
        } else if (date.isBefore(this.today)) {
          daysWithMissed.push(date);
        }
      }
    }

    this.patch({
      logsThisWeek: readingsThisWeek.length,
      goalCompletion: readingsThisWeek.length / 7,
      datesWithReadings,
      datesWithMissed: daysWithMissed
    });
  };

  get listOfDates(): Dayjs[] {
    const start = this.startOfWeek;
    const end = this.endOfWeek;

    let d = start.clone();
    const dates = [];
    while (d <= end) {
      dates.push(d);
      d = d.add(1, "day");
    }
    return dates;
  }

  get listOfDatesDisplay(): Dayjs[] {
    const start = this.startOfWeek.subtract(1, "day");
    const end = this.endOfWeek.add(1, "day");

    let d = start.clone();
    const dates = [];
    while (d <= end) {
      dates.push(d);
      d = d.add(1, "day");
    }
    return dates;
  }
}
